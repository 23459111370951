<template>
  <div class="flex flex-col w-full items-center justify-center">
    <div v-if="useRoute().query.redirect!=null" class="flex flex-row items-center justify-center p-2 rounded-md w-full max-w-sm bg-a-secondary-lightest text-a-secondary">
      <Icon name="ic:baseline-check-circle" class="text-xl mx-0.5" />
      <p>Please login first to continue</p>
    </div>
    <p class="flex flex-row items-center justify-center text-2xl font-semibold">Sign In</p>
    <form
        @submit.prevent="submitLoginForm"
        class="flex flex-col items-center justify-center w-full my-2"
    >
      <div class="flex flex-col my-2 max-w-sm w-10/12 mx-auto">
        <BaseTextInput
            field-name="email"
            label-text="Email"
            label-style-classes="text-base font-semibold"
            :arrange-label-and-input-vertically="true"
            validation-label="Email"
            fieldType="email"
            validation-rules="required|email"
            v-model="loginForm.email"
            :showErrorIcon="true"
        />
      </div>
      <div class="flex flex-col my-2 max-w-sm w-10/12 mx-auto">
        <BasePasswordInput
            :isConfirmable="false"
            password-field-name="password"
            label-text="Password"
            label-style-classes="text-base font-semibold"
            :arrange-label-and-input-vertically="true"
            validation-label="Password"
            validation-rules="required"
            v-model:password="loginForm.password"
            :showErrorIcon="true"
        />
      </div>

      <BaseButton
          primary-color-class="a-secondary"
          hovered-background-color-class="a-secondary-dark"
          text-color-class="white"
          margin-classes="mx-auto my-3"
          :is-submit="true"
          :loading="isLoggingIn"
      >
        <p>Login</p>
      </BaseButton>
    </form>
    <div class="flex flex-row items-center justify-center text-sm w-64 max-w-sm border rounded border-a-neutral-lightest my-1 px-2 py-1 hover:bg-a-secondary hover:text-white cursor-pointer transition-all duration-300 ease-in-out"
    @click="makeGoogleSignInRequest"
    >
      <NuxtImg src="/images/main/google_login.png" class="mx-1" />
      <p>SignIn with Google</p>
    </div>
    <div class="flex flex-row items-center justify-center w-64 text-sm max-w-sm border rounded border-a-neutral-lightest my-1 px-2 py-1 hover:bg-a-secondary hover:text-white cursor-pointer transition-all duration-300 ease-in-out"
    @click="makeFacebookSignInRequest"
    >
      <NuxtImg src="/images/main/fb_login.png" class="mx-1" />
      <p>SignIn with Facebook</p>
    </div>
    <div class="text-sm text-center font-semibold py-1 mt-8">
      Don't have an account?
      <nuxt-link
          to="/register"
          class="text-a-secondary select-none hover:font-bold hover:underline cursor-pointer"
      >Register
        Now
      </nuxt-link>
    </div>
    <div class="text-sm text-center font-semibold py-1">
      Forgot your password?
      <nuxt-link
          :to="{
        name: 'forgot-password',
      }"
          class="text-a-secondary select-none hover:font-bold hover:underline cursor-pointer"
      >Reset
      </nuxt-link>
    </div>
  </div>
</template>

<script
    setup
    lang="ts"
>
interface LoginForm {
  email: string,
  password: string,
  device_name: "web",
  token: string
}

const authManager = useAuthManager();
const {
  setErrorAlert,
  setSuccessAlert,
  unsetAlert,
} = useAlertSetter();
onUnmounted(async () => {
  unsetAlert();
});

const loginForm = ref<LoginForm>({
  email: "",
  password: "",
  device_name: "web",
  token: ""
} as LoginForm);

const isLoggingIn = ref(false);

definePageMeta({
  layout: "login-register",
  middleware: [
    'unauthenticated',
  ],
});

useHead({
  title: "Alahddin : Login",
});

const {handleSubmit} = useForm();

async function makeGoogleSignInRequest(){
  await authManager.loginWithGoogle();
}

async function makeFacebookSignInRequest(){
  await authManager.loginWithFacebook();
}


function getFreshLoginForm(): LoginForm {
  return {
    email: "",
    password: "",
    device_name: "web",
    token: ""
  };
}

const submitLoginForm = handleSubmit(async (values: object, {resetForm}: any) => {
    isLoggingIn.value = true;
    await authManager
        .login(loginForm.value, decodeURIComponent(useRoute().query.redirect as string??"/dashboard"))
        .then(() => {
          setSuccessAlert(
              "Login Successful"
          );
        })
        .catch((error) => {
          setErrorAlert(error.data);
        })
        .finally(() => {
          loginForm.value =
              getFreshLoginForm();
          resetForm();
          isLoggingIn.value = false;
        });
});
</script>

<style></style>
